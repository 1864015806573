export const environment = {
  production: false,
  hmr: false,
  graphqlServer: "https://kppro-api.techlabz.in/graphql",
  // graphqlServer: "https://api.mykindersteps.com/graphql",
  firebase: {
    apiKey: "AIzaSyDQNTxxYqPlrVdvPliKU0NMco9fitNzf4Q",
    authDomain: "kinderpass-pro.firebaseapp.com",
    databaseURL: "",
    projectId: "kinderpass-pro",
    storageBucket: "kinderpass-pro.appspot.com",
    messagingSenderId: "526708005536"
  }
};
