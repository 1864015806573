import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '../../@fuse/shared.module';
import { ForgotpswComponent } from './forgotpsw.component';

const routes = [
  {
      path     : 'forgotpassword',
      component: ForgotpswComponent
  }
];

@NgModule({
  declarations: [
    ForgotpswComponent
  ],
  imports     : [
      RouterModule.forChild(routes),
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      FuseSharedModule
  ]
})
export class ForgotpswModule
 {

}


